import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "@constants";

const useStyles = makeStyles((theme) => ({
  layoutContainer: {
    width: "100%",
    minHeight: "100vh",
    backgroundColor: "#f0f0f0",
  },
  sidebarLeft: {
    backgroundColor: "#fafafa",
    height: "100%",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  content: {
    flex: 1,
    height: "100%",
  },
  header: {
    flex: 1,
    height: theme.spacing(8),
    backgroundColor: "#262626",
    marginBottom: theme.spacing(2),
  },
  layoutMain: {
    display: "flex",
    padding: "40px 0 40px 40px",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("xs")]: {
      margin: theme.spacing(0),
      padding: theme.spacing(1),
    },
  },
  mainContent: {
    flex: 1,
    backgroundColor: "lightblue",
    marginRight: theme.spacing(2),
    padding: "25px 40px 100px 40px",
    [theme.breakpoints.down("xs")]: {
      marginRight: theme.spacing(0),
      padding: "15px 15px 20px 15px",
    },
  },
  layoutSidebarRight: {
    width: theme.spacing(20),
    flex: 1,
    margin: "-85px 10px 0px",
    [theme.breakpoints.down("xs")]: {
      padding: "15px",
      margin: 0,
      float: "none",
      display: "none",
      width: "100%",
    },
  },
  mainBodyContainer: {
    flexDirection: "row",
    gap: 30,
    paddingTop: 10,
    "@media (max-width: 780px)": {
      flexDirection: "column",
      padding: "0px 0px",
    },
  },
  footer: {
    marginTop: theme.spacing(4),
    height: theme.spacing(4),
  },
  menuIcon: {
    color: "red",
  },
  menuButton: {    
    position: "absolute",
    top: theme.spacing(2),
    left: theme.spacing(2),
    display: "none", // Hide the button in desktop view
    [theme.breakpoints.down("xs")]: {
      display: "block", // Show the button in mobile view
    },
  },
  mobileDrawer: {
    width: "80vw", // Set the width of the mobile drawer
  },
  iconText: {
    fontSize: ".6rem",
    color: "#fff",
    paddingLeft: 5,
  }
}));

export { useStyles };
