import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { BackdropProvider, HomeModelsProvider } from "@contexts";
import { AdminLayout, MainLayout2, Home } from "@components";
import { Details } from "./Details";
import { AdminDashboard } from "./AdminDashboard";
import { Services } from "./Services";
import { Memberships } from "./Memberships";
import DisclaimerPopup from "./Modals/MainDisclaimer";

function App() {
  const [popupAccepted, setPopupAccepted] = useState(false);

  useEffect(() => {
    const storedPopupAccepted = localStorage.getItem('popupAccepted');
    setPopupAccepted(Boolean(storedPopupAccepted));
  }, []);

  return (
    <BackdropProvider>
      {!popupAccepted && (
        <DisclaimerPopup />
      )}
      <HomeModelsProvider>
        <Router>
          <Routes>
            {/* Layout para las rutas principales */}
            <Route element={<MainLayout2 />}>
              <Route path="/" element={<Home />} />
              <Route path="/test" element={<Home />} />
              <Route path="/show/:id" element={<Details />} />
            </Route>

            {/* Layout para el panel de administración */}
            <Route element={<AdminLayout />}>
              <Route path="/dashboard" element={<AdminDashboard />} />
              <Route path="/services" element={<Services />} />
              <Route path="/memberships" element={<Memberships />} />
              <Route path="/user" element={<Services />} />
            </Route>
          </Routes>
        </Router>
      </HomeModelsProvider>
    </BackdropProvider>
  );
}

export default App;
