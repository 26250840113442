declare global {
  interface Window {
    dataLayer: any[]; // Ajusta el tipo si es necesario
  }
}


const sendAnalyticsEvent = (eventName: string, options: Record<string, any>) => {
  const dataLayer = window.dataLayer || [];
  dataLayer.push({
    event: eventName,
    ...options,
  });
  window.dataLayer = dataLayer;
};

export { sendAnalyticsEvent }