import { FC } from "react";
import { Grid, Box, Divider, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { useStyles } from "./styles";
import { ModelServicesOffered } from "src/components/Home/types";

type Data = {
  data: ModelServicesOffered[];
};

const ServicesTable: FC<Data> = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} className={classes.container}>
      <Grid item xs={12} className={classes.tableHeader}>
        <Grid item xs={7}>
          <Typography variant="body1" className={classes.tableFirstColumnHeader}>
            Services
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="body1" className={classes.tableSecondColumnHeader}>
            Included
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="body1" className={classes.tableThirdColumnHeader}>
            Extra
          </Typography>
        </Grid>
      </Grid>
      {data && data.map((el, index) => {
        return (
          <Grid item xs={12} className={classes.tableContentContainer} key={index}>
            <Grid item xs={7} className={classes.tableFirstColumn}>
              {el.name}
            </Grid>
            <Grid item xs={2} className={classes.includeSection}>
              {el.included ? (
                <CheckIcon
                  fontSize="inherit"
                  className={classes.tableSecondColumnTrue}
                />
              ) : (
                <CloseIcon
                  fontSize="inherit"
                  className={classes.tableSecondColumnFalse}
                />
              )}
            </Grid>
            <Grid item xs={3}>
              <Typography variant="body1" className={classes.tableThirdColumn}>
                {el.extraCharge ? (
                  <>
                    {el.extraCharge} &euro;
                  </>
                ) : null}
              </Typography>
            </Grid>
            <Divider/>
          </Grid>
        )})}
    </Grid>
  );
};

export { ServicesTable };
