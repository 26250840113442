import { makeStyles } from "@material-ui/core/styles";
import { Theme } from '@mui/material/styles'
import { COLORS } from "@constants";

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    fontFamily: "sans-serif",
    padding: theme.spacing(2),
  },
  tableContentContainer: {
    display: "flex",
    flexDirection: "row",
    padding: theme.spacing(0),
    paddingBottom: theme.spacing(1),
  },
  tableFirstColumn: {
    width: "40%",
    //padding: "0px 20px",
    color: COLORS.tableText,
    fontWeight: 600,
    fontSize: ".8rem",
  },
  tableSecondColumn: {
    width: "30%",
    fontWeight: 100,
    fontSize: ".8rem",
    paddingLeft: 10,
  },
  tableColumn: {
    textAlign: "left",
  },
  divider: {
    margin: theme.spacing(1, 0),
    // borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

export { useStyles };
