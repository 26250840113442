//import config from "@config";
import config from "@config";

interface ILoginResponse {
  message?: string;
  data?: string;
  errorType?: string;
  errorMessage?: string;
}


const { baseURL } = config

const handleApiLogin = async (
  email: string,
  password: string
): Promise<ILoginResponse> => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Accept-Language", "{{language}}");

  var urlencoded = new URLSearchParams();
  urlencoded.append("email", email);
  urlencoded.append("password", password);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  const response = await fetch(
    `${baseURL}/v1/auth/login`,
    requestOptions
  )
    .then((response) => response.json())
    .then((result) => {
      return result;
    })
    .catch((error) => console.log("error", error));

  return response;
};

const handleApiRegister = async (
  email: string,
  password: string,
  confirmPassword: string
): Promise<ILoginResponse> => {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("email", email);
  urlencoded.append("password", password);
  urlencoded.append("passwordConfirm", confirmPassword);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
  };

  const response = await fetch(
    `${baseURL}/v1/auth/register`,
    requestOptions
  );

  if (!response.ok) {
    throw new Error(`HTTP error, status = ${response.status}`);
  }

  const result = await response.json();

  return result;
};

export { handleApiLogin, handleApiRegister };
