import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";
import { Box } from "@mui/material";
import { useStyles } from "./styles";

const Footer: React.FC = (props) => {
  const classes = useStyles();

  return (
    <Container>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            About Us
          </Typography>
          <Typography variant="body2" color="text.secondary">
            We are BestEuroGirls company, dedicated to providing the best service to our
            customers.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="h6" color="text.primary" gutterBottom>
            Contact Us
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            123 Main Street, Anytown, USA
          </Typography> */}
          <Typography variant="body2" color="text.secondary">
            Email: <a href="mailto:contact@besteurogirls.com" className={classes.email}>contact@besteurogirls.com</a>
          </Typography>
          {/* <Typography variant="body2" color="text.secondary">
            Phone: +1 234 567 8901
          </Typography> */}
        </Grid>
        <Grid item xs={12} sm={4}>
          {/* <Typography variant="h6" color="text.primary" gutterBottom>
            Follow Us
          </Typography>
          <Link href="https://www.facebook.com/" color="inherit">
            <Facebook />
          </Link>
          <Link
            href="https://www.instagram.com/"
            color="inherit"
            sx={{ pl: 1, pr: 1 }}
          >
            <Instagram />
          </Link>
          <Link href="https://www.twitter.com/" color="inherit">
            <Twitter />
          </Link> */}
        </Grid>
      </Grid>
      <Box mt={5}>
        <Typography variant="body2" color="text.secondary" align="center">
          {"Copyright © "}
          <Link color="inherit" href="https://your-website.com/">
            BestEuroGirls.com
          </Link>{" "}
          {new Date().getFullYear()}
          {"."}
        </Typography>
      </Box>
    </Container>
  );
};

export { Footer };
