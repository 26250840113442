import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "@constants";

const useStyles = makeStyles({
  leftPanel: {
    boxShadow: "0 0 8px 0 rgba(0,0,0,.33)",
  },
  countryContainer: {
    display: "flex",
    padding: "5px 10px",
    gap: 10,
  },
  navLinkText: { color: "white", marginTop: 20 },
  itemList: {
    color: "#f8f8f8",
    fontSize: "12px",
    listStyleType: "none",
    margin: 0,
    padding: 0,
    borderBottom: "1px solid #ddd"
  },
  citiesList: {
    background: "#f6f6f6",
    borderTop: "1px solid #cecece",
    position: "relative",
    margin: "5px -10px -5px",
    listStyleType: "none",
    padding: "3px 0",
    fontSize: "11px",
    display: "none",
    borderLeft: "3px solid #cb2845",
  },
  logo: {
    backgroundImage: "linear-gradient(90deg, rgba(43,40,41,1) 0%, rgba(98,82,84,0.6446953781512605) 50%, rgba(43,40,41,1) 100%)",
    textAlign: "center",
    paddingTop: "20px",
    margin: 0,
    cursor: "pointer",
    '& > img': {
      width: "80%",
      height: "auto",
    }
  }
});

export { useStyles };
