import React from 'react'
import PropTypes from 'prop-types'
import { List, Grid } from "@material-ui/core";
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import Collapse from '@mui/material/Collapse'
import AddBoxSharpIcon from '@mui/icons-material/AddBoxSharp';
import IndeterminateCheckBoxSharpIcon from '@mui/icons-material/IndeterminateCheckBoxSharp';
import "/node_modules/flag-icons/css/flag-icons.min.css";
import CountryMenuItemComponent from './countryMenuItemComponent'
import { useGetModelsService } from '@features';
import { useStyles } from "./styles";

export const CountryMenuItemPropTypes = {
  name: PropTypes.string.isRequired,
  link: PropTypes.string,
  acronym: PropTypes.string,
  cities: PropTypes.array,
  totalModels: PropTypes.number,
}

// TypeScript compile-time props type, infered from propTypes
// https://dev.to/busypeoples/notes-on-typescript-inferring-react-proptypes-1g88
type CountryMenuItemPropTypes = PropTypes.InferProps<typeof CountryMenuItemPropTypes>
type CountryMenuItemPropsWithoutItems = Omit<CountryMenuItemPropTypes, 'cities'>

// Improve child cities declaration
export type CountryMenuItemProps = CountryMenuItemPropsWithoutItems & {
  cities?: CountryMenuItemProps[]
  children?: boolean
}

const CountryMenuItem: React.FC<CountryMenuItemProps> = props => {
  const { name, link, acronym, cities = [], children=false, totalModels = 0 } = props
  const classes = useStyles()
  const isExpandable = cities && cities.length > 0
  const [open, setOpen] = React.useState(false)
  const { handleGetModelsByCity } = useGetModelsService();

  const handleClick = (name: string) => {
    handleGetModelsByCity(name);
  }

  const handleIconClick = (event: React.MouseEvent) => {
    setOpen(!open);
    event.stopPropagation();
  };

  const MenuItemRoot = (
    <CountryMenuItemComponent
      className={`${classes.menuItem} ${children ? 'childBackground' : ''}`}
      link={link}
      onClick={() => handleClick(name)}
    >
      {!children && (
        <>
          <Grid item xs={1} className={classes.isExpandable}>
            {isExpandable && !open && (
              <AddBoxSharpIcon fontSize="small" onClick={handleIconClick} />
            )}
            {isExpandable && open && (
              <IndeterminateCheckBoxSharpIcon fontSize="small" onClick={handleIconClick} />
            )}
          </Grid>
          <Grid item xs={1} className={classes.countryIcon}>
            {!!acronym && (
              <ListItemIcon className={classes.menuItemIcon}>
                <span className={`fi fi-${acronym.toLowerCase()}`} style={{ marginRight: '8px' }} />
              </ListItemIcon>
            )}
          </Grid>
          <Grid item xs={8}>
            <span className={classes.countryName} >{name}</span>
          </Grid>
          <Grid item xs={2} className={classes.countryCount}>
            ({totalModels})
          </Grid>
        </>
      )}
      {children && (
        <>
          <Grid item xs={10} className={classes.citySection}>
            <span className={classes.cityName}>
              {name}
            </span>
          </Grid>
          <Grid item xs={2} className={classes.countryCount}>
            ({totalModels})
          </Grid>
        </>
      )}
      
      {/* Display the expand menu if the item has children */}
    </CountryMenuItemComponent>
  );
  

  const MenuItemChildren = isExpandable ? (
    <Collapse in={open} timeout="auto" unmountOnExit>
      <Divider />
      <List component="div" disablePadding>
        {cities.map((item, index) => (
          <CountryMenuItem {...item} key={index} children={true}/>
        ))}
      </List>
    </Collapse>
  ) : null

  return (
    <>
      {MenuItemRoot}
      {MenuItemChildren}
    </>
  )
}

export default CountryMenuItem
