const COLORS = {
  main: "#cb2845",
  primary: "#EB1D36",
  secondary: "#FFFFFF",
  success: "#0EBC69",
  BG_Darker: "#1A1A1A",
  BG_Dark: "#222222",
  BG_Light: "#363636",
  title: "#cb2845",
  tableBorder: "#cb2845",
  tableText: "#b3223c",
  Grey: "#9c9c9c",
  TableBackground: "#c9c9c9",
};

export { COLORS };
