import { AxiosRequestConfig } from 'axios'
import { GetModelsPayload } from './types';

const getCountries = (): AxiosRequestConfig => ({
  url: '/countries',
  method: 'GET',
})

const getModels = (payload: GetModelsPayload): AxiosRequestConfig => {
  let params:any = {};
  if (payload.city)
    params['city'] = encodeURIComponent(payload.city)

  return {
    url: '/models',
    method: 'GET',
    params: { ...params },
  }
};

export { getCountries, getModels }
