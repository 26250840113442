import React, { useState } from "react";
import { Grow, Box, Typography, Grid, Button } from "@material-ui/core";
import { useStyles } from "./styles";
import { ContactResponse } from "src/features/Details/type";
import { sendAnalyticsEvent } from "@utils";

type Data = {
  data: ContactResponse;
};

const ContactTable: React.FC<Data> = ({ data }) => {
  const classes = useStyles();
  const [fullPhoneVisible, setFullPhoneVisible] = useState(false);

  const handleShowFullPhoneClick = () => {
    setFullPhoneVisible(true);
    sendAnalyticsEvent('click', {
      event_category: 'Contact models',
      event_label: 'Show phone number',
    });
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12}>
        <Typography>
          <span className={classes.cardTitle}>Contact</span>
        </Typography>
      </Grid>
      <Grid item xs={3} sm={2} className={classes.contactLabels}>
        <Box>Phone:</Box>
        <Box>Country:</Box>
        <Box>City:</Box>
      </Grid>
      <Grid item xs={9} sm={10} className={classes.contactValues}>
        <Box>
          {fullPhoneVisible ? (
            <Grow in={fullPhoneVisible} timeout={1000}>
              <span>{data.phone}</span>
            </Grow>
          ) : (
            <span>{data.phone.substring(0, 6)}</span>
          )}
          {!fullPhoneVisible && (
            <Button
              variant="outlined"
              size="small"
              onClick={handleShowFullPhoneClick}
              className={classes.showMoreButton}
            >
              Show
            </Button>
          )}
        </Box>
        <Box>{data.country}</Box>
        <Box>{data.city}</Box>
      </Grid>
    </Grid>
  );
};

export { ContactTable };
