import { makeStyles, createStyles } from '@mui/styles'
import { COLORS } from "@constants";

const useStyles = makeStyles(theme =>
  createStyles({
    menuItem: {
      paddingRight: theme.spacing(0),
      paddingLeft: theme.spacing(0),
      borderTop: "1px solid #ddd",
      background: '#fff',
      '&.active': {
        background: 'rgba(0, 0, 0, 0.08)',
        '& .MuiListItemIcon-root': {
          color: '#fff',
        },
      },
      '&.childBackground': {
        background: '#f0f0f0',
        //borderLeft: "4px solid #ecb5bf",
      },
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    isExpandable: {
      margin: "0 5px",
      // display: 'flex',
      // justifyContent: 'center',
      alignItems: 'left',
    },
    countryIcon: {
      margin: "0 5px",
      display: 'flex',
    },
    countryName: {
      alignItems: 'left',
      fontSize: ".8rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    cityName: {
      alignItems: 'left',
      fontSize: ".6rem",
      fontFamily: "Roboto, Helvetica, Arial, sans-serif",
      fontWeight: 400,
      lineHeight: 1.5,
      letterSpacing: "0.00938em",
    },
    citySection: {      
      paddingLeft: "10px",
      '& listItemText':{
        fontSize:'0.6em',//Insert your required size
      },
      [theme.breakpoints.down('sm')]: {
        paddingLeft: "2rem",
      },
    },
    countryCount: {
      alignItems: 'right',
    }
  }),
)

export { useStyles };
