import React, { FC, useState, useEffect, useContext } from "react";
import { useParams } from "react-router";
import {
  Box,
  Typography,
  Grid
} from "@material-ui/core";
import { ContactTable, ProfileTable, ServicesTable } from "@components";
import { useApiCall } from "@hooks";
import { useStyles } from "./styles";
import { getModelById } from "./api";
import ImageGallery from 'react-image-gallery';
import 'react-image-gallery/styles/scss/image-gallery.scss';
import { ModelResponse, getModelByIdResponse } from "./type";
import { BackdropContext } from "@contexts";

//const Details = (id:number) => {
const Details: FC = () => {
  const classes = useStyles();
  const { id } = useParams<{ id: string }>();
  const [model, setModel] = useState<ModelResponse | undefined>(undefined);
  const { setBackdropIsOpen } = useContext(BackdropContext)
  const [getModelByIdApi, isLoading] = useApiCall<string, getModelByIdResponse>(
    getModelById,
  )

  useEffect(() => {
    setBackdropIsOpen(isLoading)
  }, [isLoading])

  useEffect(() => {
    if (!model) {
      const fetchData = async () => {
        const response: getModelByIdResponse = await getModelByIdApi(id)
        if (response) setModel(response?.model)
      }
      fetchData()
    }
  }, [])

  if (!model) {
    return <span></span>;
  }

  return (
    <>
      <Grid item xs={12} className={classes.modelDescriptionContainer}>
        <Box className={classes.modelDescription}>
          <Typography>
            <span className={classes.cardTitle}>{model.fullname} </span> <span style={{ fontSize: "1.2em", fontStyle: "italic" }}>{model.shortDescription}</span>
          </Typography>
          <Box className={classes.lastLogin}>Last seen online: Today</Box>
          <Typography>
            <strong style={{ fontSize: "1.5em", color: model.showOnline ? '#50df50' : 'red' }}>
              {` ${model.showOnline ? 'online' : 'offline'}`}
            </strong>
          </Typography>
          <Box dangerouslySetInnerHTML={{ __html: model.richDescription }} />
        </Box>
      </Grid>
      <Grid item sm={12} className={classes.mainContainer}>
        <Grid item xs={12} sm={5} lg={4} className={classes.imageGalleryContainer}>
          <Box className={classes.imageGallery}>
            {model.images && (
              <ImageGallery
                showPlayButton={false}
                items={model.images.map(el => (
                  {
                    original: el.imageUrl,
                    thumbnail: el.thumbnailImageUrl,
                  }
                ))}
              />
            )}
          </Box>
        </Grid>
        <Grid item xs={12} sm={7} lg={8}>
          {model.attributes && model.attributes.length > 0 ? (
            <Grid className={`${classes.section} ${classes.profileSection}`}>
              <ProfileTable data={model?.attributes} />
            </Grid>
          ) : null}
          {model.contact && (
            <Grid className={`${classes.section} ${classes.modelContact}`}>
              <ContactTable data={model?.contact} />
            </Grid>
          )}
          {model.servicesOffered && (
            <Grid className={`${classes.section} ${classes.modelServices}`}>
              <ServicesTable data={model?.servicesOffered} />
            </Grid>
          )}
        </Grid>
      </Grid>
    </>

  )
};

export { Details };
