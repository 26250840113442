import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles"; 
import {
  Box,
  Typography,
  Grid,
  Paper
} from "@mui/material";
import { useStyles } from "./styles";
import { useGetModelsService } from "@features";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary
}));

const HomeCard: React.FC = () => {
  const history = useNavigate();
  const classes = useStyles();
  const { handleGetModels, homeModels, showModelsDisclaimer } = useGetModelsService();

  useEffect(() => {
    if (!homeModels.length) handleGetModels()
  }, [])  

  const handleClick = (id: string) => {
    history.push(`/show/${id}`);
  };

  const getMembershipTagClass = (membership: string) => {
    let tagClass = `${classes.cardImage} ${classes.tag} ${classes.tagMembership}`;
  
    if (membership === "VIP") {
      tagClass += ` ${classes.tagVip}`;
      return tagClass;
    }

    if (membership === "TOP") {
      tagClass += ` ${classes.tagTop}`;
      return tagClass;
    }

    tagClass += ` ${classes.tagFree}`;
    return tagClass;
  };

  const getProfileStatusTagClass = (verified: boolean) => {
    let tagClass = `${classes.tagProfileStatus}`;
    if (verified) {
      tagClass += ` ${classes.tagVerified}`;
      return tagClass;
    }
    tagClass += ` ${classes.tagNotVerified}`;
    return tagClass;
  }

  return (
    <Grid container spacing={2}>
      {showModelsDisclaimer && (
        <Grid item xs={12}>
          <Item>
            <Typography variant="h2">Ops.</Typography>
            <Typography variant="body1">
              There are no models available in your area. Please, take a look at these ones.
            </Typography>
          </Item>
        </Grid>
      )}
      {homeModels.map((model) => {
        return (
          <Grid key={`model-${model.id}`} item md={2.4} xs={6}>
            <Box className={classes.cardContainer} onClick={() => handleClick(model.topic)}>
              <Box className={classes.cardTextContainer}>{model.fullname}</Box>
              <div
                className={getMembershipTagClass(model.membership)}
                data-membership={model.membership}
              >
                <div
                  className={getProfileStatusTagClass(model.verified)}
                  data-verification={model.verified ? "VERIFIED" : "NOT VERIFIED"} // Contenido para el pseudo-elemento ::before de verification
                />
                <img src={model.imageUrl} alt="" className={classes.cardImage} />
              </div>
              <Box className={classes.cardTextContainer}>{model.contact.city ? model.contact.city.name : model.contact.country?.name}</Box>
            </Box>
          </Grid>
        )
      })}
    </Grid>
  );
};

export { HomeCard };
