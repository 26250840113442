import React, { ChangeEvent, useContext, useState } from "react";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useStyles } from "./styles";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Typography } from "@mui/material";
import { toastIt, validateEmail, verifyPasswordFormat } from "@utils";
import { ToastContainer } from "react-toastify";
import { BackdropContext, GlobalContext } from "@contexts";
import { handleApiLogin, handleApiRegister } from "@api";

interface ModalProps {
  open: boolean;
  setOpen: (value: boolean) => void;
}

interface ILoginFields {
  email: string;
  password: string;
  confirmPassword: string;
}

const LoginModal: React.FC<ModalProps> = ({ open, setOpen }) => {
  const [selectedOption, setSelectedOption] = useState<string>("login");
  const { setBackdropIsOpen } = useContext(BackdropContext)
  const [formFields, setFormFields] = useState<ILoginFields>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [registerFormFields, setRegisterFormFields] = useState<ILoginFields>({
    email: "",
    password: "",
    confirmPassword: "",
  });
  const { setToken } = useContext(GlobalContext);
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  const handleFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormFields({ ...formFields, [name]: value });
  };

  const handleRegisterFieldChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setRegisterFormFields({ ...registerFormFields, [name]: value });
  };

  const handleLogin = async () => {
    const { email, password } = formFields;
    if (!validateEmail(email)) {
      toastIt("Wrong email format, please try again.", 2);
      return;
    }
    if (!verifyPasswordFormat(password)) {
      toastIt(
        "Wrong password format,passwords must contain at least 8 characters, 1 uppercase letter,1 lowercase letter, and a number.",
        2
      );
      return;
    }
    setBackdropIsOpen(true);
    const loginResponse = await handleApiLogin(email, password);
    setBackdropIsOpen(false);
    if (!loginResponse.data && loginResponse.message) {
      toastIt(loginResponse.message, 2);
      return;
    }
    if (loginResponse.message && loginResponse.data) {
      setToken(loginResponse.data);
      setOpen(false);
    }
  };

  const handleRegister = async () => {
    const { email, password, confirmPassword } = registerFormFields;
    if (!validateEmail(email)) {
      toastIt("Wrong email format, please try again.", 2);
      return;
    }
    if (!verifyPasswordFormat(password)) {
      toastIt(
        "Wrong password format,passwords must contain at least 8 characters, 1 uppercase letter,1 lowercase letter, and a number.",
        2
      );
      return;
    }
    if (password !== confirmPassword) {
      toastIt("Passwords don't match, please try again.", 2);
      return;
    }
    try {
      setBackdropIsOpen(true);
      const result = await handleApiRegister(email, password, confirmPassword);
      setBackdropIsOpen(false);
      if (result.message) {
        toastIt(result.message, 1);
        setSelectedOption("login");
      }
    } catch (error) {
      toastIt("Email already exists.", 2);
      console.error("Error:", error);
    }
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose} className={classes.modal}>
        <Box className={classes.paper}>
          <Box className={classes.leftSection}>
            <Box
              className={classes.closeButtonMovile}
              onClick={() => setOpen(false)}
            >
              <CloseIcon fontSize="large" />
            </Box>
            <Box className={classes.leftSectionOverlay} />
            <Typography variant="h3" className={classes.title}>
              LOREM IPSUM
            </Typography>
            <Typography variant="h6" className={classes.subtitle}>
              Many desktop publishing packages and web page editors now use
            </Typography>
            <Box className={classes.btnContainer}>
              <Box
                className={
                  selectedOption === "login"
                    ? classes.selectionBtnSelected
                    : classes.selectionBtn
                }
                onClick={() => setSelectedOption("login")}
              >
                <Typography variant="body1">Login</Typography>
              </Box>
              <Box
                className={
                  selectedOption === "register"
                    ? classes.selectionBtnSelected
                    : classes.selectionBtn
                }
                onClick={() => setSelectedOption("register")}
              >
                <Typography variant="body1">Register</Typography>
              </Box>
            </Box>
          </Box>
          <Box className={classes.rightSection}>
            <Box className={classes.closeButton} onClick={() => setOpen(false)}>
              <CloseIcon fontSize="large" />
            </Box>
            <Typography variant="h2">LOGO</Typography>
            {/* <Box className={classes.loginOption}>
              <Box className={classes.opcionImage}>
                <img src="/vector.png" alt="" />
              </Box>
              <Typography variant="body1" className={classes.optionText}>
                Ingresar con...
              </Typography>
            </Box>
            <Box className={classes.loginOption}>
              <Box className={classes.opcionImage}>
                <img src="/vector.png" alt="" />
              </Box>
              <Typography variant="body1" className={classes.optionText}>
                Ingresar con...
              </Typography>
            </Box> */}

            <form className={classes.form}>
              {selectedOption === "login" ? (
                <>
                  <TextField
                    name="email"
                    variant="filled"
                    className={classes.textField}
                    value={formFields.email}
                    label="Email"
                    type="email"
                    fullWidth
                    onChange={handleFieldChange}
                  />
                  <TextField
                    name="password"
                    variant="filled"
                    className={classes.textField}
                    value={formFields.password}
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={handleFieldChange}
                  />
                  <Button
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleLogin}
                  >
                    Login
                  </Button>
                </>
              ) : (
                <>
                  <TextField
                    name="email"
                    variant="filled"
                    className={classes.textField}
                    value={registerFormFields.email}
                    label="Email"
                    type="email"
                    fullWidth
                    onChange={handleRegisterFieldChange}
                  />
                  <TextField
                    name="password"
                    variant="filled"
                    className={classes.textField}
                    value={registerFormFields.password}
                    label="Password"
                    type="password"
                    fullWidth
                    onChange={handleRegisterFieldChange}
                  />
                  <TextField
                    name="confirmPassword"
                    variant="filled"
                    className={classes.textField}
                    value={registerFormFields.confirmPassword}
                    label="Confirm password"
                    type="password"
                    fullWidth
                    onChange={handleRegisterFieldChange}
                  />
                  <Button
                    fullWidth
                    className={classes.button}
                    variant="contained"
                    color="primary"
                    onClick={handleRegister}
                  >
                    Register
                  </Button>
                </>
              )}
            </form>
            <Typography variant="body1">
              ¿desktop publishing packages?
            </Typography>
          </Box>
        </Box>
      </Modal>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </Box>
  );
};

export { LoginModal };
