import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BackdropContext, HomeModelsContext } from '@contexts';
import { GetModelsPayload, GetModelsResponse, Model } from './types';
import { getModels } from './api';
import { useApiCall } from '@hooks';
import { shuffle } from 'lodash';

export const useGetModelsService = () => {
  const history = useNavigate(); // Obtén el objeto de historial
  const { homeModels, setHomeModels, showModelsDisclaimer, setShowModelsDisclaimer } = useContext(HomeModelsContext);
  const { setBackdropIsOpen } = useContext(BackdropContext)

  const [getModelsApi, isLoading] = useApiCall<GetModelsPayload, GetModelsResponse>(
    getModels,
  );

  useEffect(() => {
    setBackdropIsOpen(isLoading)
  }, [isLoading])

  const checkRootPath = () => {
    if (window.location.pathname !== '/') {
      history.push('/');
    }
  }

  const hanldeSetHomeModels = (models: Model[]) => {
    const vipModels = models.filter(model => model.membership === 'VIP');
    const topModels = models.filter(model => model.membership === 'TOP');
    const verifiedTrueModels = models.filter(model => model.membership === null && model.verified === true);
    const verifiedFalseModels = models.filter(model => model.membership === null && model.verified === false);

    const shuffledVipModels = shuffle(vipModels);
    const shuffledTopModels = shuffle(topModels);
    const shuffledVerifiedTrueModels = shuffle(verifiedTrueModels);
    const shuffledVerifiedFalseModels = shuffle(verifiedFalseModels);

    const finalModelsArray = [
      ...shuffledVipModels,
      ...shuffledTopModels,
      ...shuffledVerifiedTrueModels,
      ...shuffledVerifiedFalseModels,
    ];

    setHomeModels(finalModelsArray);
  }

  const handleGetModelsByCity = async (cityName: string) => {
    const response = await getModelsApi({ city: cityName });
    if (response.models.length > 0) {
      setShowModelsDisclaimer(false);
      hanldeSetHomeModels(response.models);
    } else {
      await handleGetModels();
      await setShowModelsDisclaimer(true);
    }
    checkRootPath()
  };

  const handleGetModels = async () => {
    const response = await getModelsApi({});
    setShowModelsDisclaimer(false);
    hanldeSetHomeModels(response.models);
    checkRootPath()
  };

  return { handleGetModels, handleGetModelsByCity, homeModels, isLoading, showModelsDisclaimer };
};
