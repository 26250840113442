import React, { Fragment } from "react";
import { Box, Grid, Divider } from "@material-ui/core";
import { ModelAttributes } from "src/components/Home/types";
import { useStyles } from "./styles";

type Data = {
  data: ModelAttributes[];
};

const ProfileTable: React.FC<Data> = ({ data }) => {
  const classes = useStyles();

  const middleIndex = Math.ceil(data.length / 2);
  const column1Data = data.slice(0, middleIndex);
  const column2Data = data.slice(middleIndex);

  return (
    <Grid container className={classes.container}>
      <Grid item xs={6} className={classes.tableColumn}>
        {column1Data.map((el, index) => (
          <Fragment key={index}>
            <Box className={classes.tableContentContainer}>
              <Box className={classes.tableFirstColumn}>
                {el.name}
              </Box>
              <Box className={classes.tableSecondColumn}>
                {el.value}
              </Box>
            </Box>
            {/* {index < column1Data.length - 1 && <Divider className={classes.divider} />} */}
          </Fragment>
        ))}
      </Grid>
      <Grid item xs={6} className={classes.tableColumn}>
        {column2Data.map((el, index) => (
          <Fragment key={index}>
            <Box className={classes.tableContentContainer}>
              <Box className={classes.tableFirstColumn}>
                {el.name}
              </Box>
              <Box className={classes.tableSecondColumn}>
                {el.value}
              </Box>
            </Box>
            {/* {index < column2Data.length - 1 && <Divider className={classes.divider} />} */}
          </Fragment>
        ))}
      </Grid>
    </Grid>
  );
};

export { ProfileTable };
