import React, { useEffect } from 'react'
import { makeStyles, createStyles } from '@mui/styles'

import List from '@mui/material/List'
import { Box } from "@mui/material";

import CountryMenuItem from './countryMenuItem'
import { Country, GetCountriesResponse } from './types'
import { getCountries } from './api'
import { useApi } from '@hooks'

const CountriesMenu: React.FC = () => {
  const classes = useStyles()

  const getCountriesResponseGetter = (responseData: GetCountriesResponse) => responseData?.countries ?? []
  const [{ data: countries }, fetchCountries, setCountries] = useApi<GetCountriesResponse, Country[]>(getCountries, getCountriesResponseGetter, {
    baseData: [],
    //onError: () => snackbar.show(ERRORS.GENERIC_ERROR_MESSAGE),
    onError: () => { console.log('error') },
  })

  useEffect(() => {
    if (countries.length === 0) {
      fetchCountries();
    }
  }, []);

  return (
    <Box className={classes.countryList}>
      <h2 className={classes.continent}>Europe</h2>
      <List component="nav" className={classes.appMenu} disablePadding>
        {countries.map((item, index) => (
          <CountryMenuItem {...item} key={index}/>
        ))}
      </List>
    </Box>
  )
}

const drawerWidth = 240

const useStyles = makeStyles(theme =>
  createStyles({
    appMenu: {
      width: '100%',
      fontSize: "12px",
      listStyleType: "none",
      margin: 0,
      padding: 0,
      borderBottom: "1px solid #ddd",
    },
    navList: {
      width: drawerWidth,
    },
    menuItem: {
      width: drawerWidth,
    },
    menuItemIcon: {
      color: '#97c05c',
    },
    countryList: {
      paddingTop: 10,
    },
    continent: {
      fontSize: "14px",
      color: "#cb2845",
      fontWeight: 900,
      margin: "0 10px 10px",
      padding: 0,
      textTransform: "uppercase",
    }
  }),
)

export default CountriesMenu
