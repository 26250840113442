import { makeStyles } from "@material-ui/core/styles";
import { padding } from "@mui/system";
import { COLORS } from "@constants";

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 30,
    // marginTop: 100,
    // padding: "0px 30px",
    color: "white",
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(0),
      display: "inherit",
    }
  },
  modelDescriptionContainer: {
    marginBottom: 20,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  modelDescription: {
    background: "#fff",
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
    border: "1px solid #ddd",
    fontSize: "12px",
    lineHeight: "19px",
    padding: "20px 25px",
    color: "black", 
  },
  imageGalleryContainer: {
    height: "auto",
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 10,
    },
  },
  imageGallery: {
    background: "#fff",
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
    border: "1px solid #ddd",
    padding: "4px",
  },
  lastLogin: {
    color: "#4b4b4b",
    position: "relative",
    margin: "-10px 0 15px",
  },
  cardTitle: {
    color: "#4b4b4b",
    fontSize: "2rem",
    fontWeight: "bold"
  },
  profileSection: {
    backgroundColor: COLORS.TableBackground,
  },
  modelContact: {
    background: "#fff",
  },
  modelServices: {
    backgroundColor: COLORS.TableBackground,
  },
  section: {
    width: "100%",
    boxShadow: "0 2px 7px 0 rgba(0,0,0,.06)",
    border: "1px solid #ddd",
    borderTop: "4px solid #cb2845",
    marginBottom: 40,
    [theme.breakpoints.down('sm')]: {
      width: "100%",
      //margin: theme.spacing(1),
      marginBottom: 10,
    },
  }
}));

export { useStyles };

