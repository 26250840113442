import React, { FC, useState, useEffect, useContext } from "react";
import { useStyles } from "./styles";
import { Grid, IconButton, Drawer } from "@material-ui/core";
import { LocationOn as LocationOnIcon} from "@mui/icons-material";
import { Box } from "@mui/material";
import { LeftPanel } from "../LeftPanel";
import { RightPanel } from "../RightPanel";
import { HomeModelsContext } from "@contexts";
import { Footer } from "@components";

export type MainLayout2Props = {
  children?: React.ReactNode | React.ReactNode[];
};

const MainLayout2: FC<MainLayout2Props> = ({ children }) => {
  const classes = useStyles();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { homeModels } = useContext(HomeModelsContext);

  useEffect(() => {
    if (isMobileMenuOpen) {
      handleMobileMenuClose()
    }
  }, [homeModels]);

  const handleMobileMenuOpen = () => {
    setIsMobileMenuOpen(true);
  };

  const handleMobileMenuClose = () => {
    setIsMobileMenuOpen(false);
  };

  return (
    <Grid container className={classes.layoutContainer}>
      {/* Barra lateral izquierda (Desktop) */}
      <Grid item xs={2} md={2} lg={1} className={classes.sidebarLeft}>
        {/* Contenido de la barra lateral */}
        <LeftPanel />
      </Grid>
      {/* Mobile menu button */}
      <IconButton
        color="inherit"
        aria-label="menu"
        onClick={handleMobileMenuOpen}
        className={classes.menuButton}
      >
        <LocationOnIcon className={classes.menuIcon} />
        <Box className={classes.iconText}>By Country & City</Box>
      </IconButton>
      <Drawer
        anchor="left"
        open={isMobileMenuOpen}
        onClose={handleMobileMenuClose}
        classes={{ paper: classes.mobileDrawer }}
      >
        {/* Contenido de la barra lateral en versión mobile */}
        <LeftPanel onCloseMobileMenu={handleMobileMenuClose} />
      </Drawer>
      {/* Contenido principal */}
      <Grid item xs={12} sm={10} md={10} lg={11} className={classes.content}>
        <Grid item xs={12} sm={12} className={classes.header}>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.layoutMain}>
          <Grid item xs={12} sm={10} className={classes.mainBodyContainer}>
            {children}
          </Grid>
          <Box className={classes.layoutSidebarRight}>
            <RightPanel />
          </Box>
        </Grid>
        <Grid item xs={12} sm={12} className={classes.footer}>
          <Footer />
        </Grid>
      </Grid>
    </Grid>
  );
};

export { MainLayout2 };
